/*
 * Defaults and bootstrap overrides
 */

* {
  font-family: inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
    sans-serif;
  color: rgba(0, 0, 0, 0.8);
}

html,
body,
#root {
  height: 100%;
}

a, .btn-link {
  color: #0076ae;
}
a:hover {
  text-decoration: none;
}

input[type='checkbox'] {
  width: 1em;
}

.btn-primary {
  background-color: #0076ae;
}

/* 
 * Utility classes
 */

.left-0 {
  left: 0;
}

.height-full {
  height: 100%;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-100 {
  opacity: 1;
}

.line-height-1 {
  line-height: 1;
}

.flex-1 {
  flex: 1;
}

.line-height-1 {
  line-height: 1;
}

.text-1 {
  font-size: 12px;
}
.text-5 {
  font-size: 30px;
}

.bg-gray {
  background-color: #888;
}

.bg-blue {
  background-color: #0076ae;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

/*
 * Custom components
 */

.Label {
  border-bottom: 2px solid #d3d3d3;
  width: 100%;
  padding-bottom: 6px;
  margin-bottom: 20px;
}

.NoteLabel {
  cursor: pointer;
  border-radius: 2px;
  padding: 6px 9px;
  margin: 0;
  color: #0076ae;
  border: 1px solid #0076ae;
}

.NoteLabel--selected {
  background: #0076ae;
  color: #fff;
}

.Parallelogram {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 2px;
  transform: skew(16deg);
}

.sequence-item {
  height: 40px;
  border: 1px solid #abb;
  margin: 1px;
  border-radius: 1px;
}

.sequence-item-selected {
  background-color: #0076ae;
}

.sequence-item-current {
  opacity: 0.5;
  background-color: #ff4916;
}

.sequence-item-selected.sequence-item-current {
  opacity: 1;
}

/*
 * Keyboard
 */

@media (min-width: 576px) {
  .Keyrow {
    width: 550px;
  }
}

.Key {
  cursor: pointer;
  background-color: #e6e6e6;
  width: 100%;
  margin: 1px;
  padding: 0;
  flex: 1;
  border-radius: 2px;
  padding-top: 4px;
}

.Key:focus {
  outline: none;
}

.Key__note {
  font-weight: 500;
  color: #444;
}
.Key--active .Key__note {
  color: #fff;
}

.Key__cents {
  color: #888;
}
.Key--active .Key__cents {
  color: #ccc;
}

.Key__shortcut {
  background: #d4d4d4;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 6px;
  color: #888;
}
.Key--active .Key__shortcut {
  background: #0076ae;
  color: #ccc;
}

.Key--octave {
  background-color: #fff;
}

.Key--active {
  color: #fff;
  background-color: #0076ae;
}

.Keyrow {
  display: flex;
}

@media (min-width: 576px) {
  /* Based on each key being 10% width */
  .Keyrow--0 {
    margin-left: 7.5%;
  }

  .Keyrow--1 {
    margin-left: 2.5%;
  }

  .Keyrow--2 {
    margin-left: -2.5%;
  }

  .Keyrow--3 {
    margin-left: -7.5%;
  }
}
